import React, { useState } from "react";
import Container from "../../components/chat-channel/Container";
import Layout from "../../components/chat-channel/Layout";
import TitleAndMetaTags from "../../components/common/TitleAndHeader";
import { RequestForm } from "../../components/form";
import { RequestFormModal } from "../../components/modalform";
import { useMedia } from "use-media";
import {
  AutomateTestimonials,
  AutomateTestimonialsMobile,
} from "../../components/testimonials/automate";

import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import "react-accessible-accordion/dist/fancy-example.css";
import PricingWrapperV2, {
  SubscriptionPlanChange,
} from "../../components/common/PricingCard";

import {
  InfoIcon,
  PriceFreeIcon,
  PriceProIcon,
  PricestadardIcon,
  PriceEnterpriseIcon,
  YesIcon,
  NoIcon,
  UpArrowIcon,
  PriceBasicIcon,
  DropdownBlack,
} from "../../components/common/Icons";

const bannerImage = require("../../assets/automate/home/automate_clients_logo.svg");
const bannerImageMobile = require("../../assets/automate/home/automate_clients_logo_mobile.svg");

const OgImage = require("../../assets/automate/home/automate_home_header.png");

export default function PricingPage() {
  const [open, setOpen] = useState(false);
  const [title, setTitle] = useState(true);
  const [yearly, isYearly] = useState(false);

  const [plan, setPlan] = useState("");
  const [showThanksPage, setShowThanksPage] = useState(false);

  const [more, setMore] = useState(false);
  const [more1, setMore1] = useState(false);
  const [more2, setMore2] = useState(false);
  const [more3, setMore3] = useState(false);

  const isMobile = useMedia({ maxWidth: "520px" });

  return (
    <>
      <TitleAndMetaTags
        title="Free Workflow Automation Pricing | Workativ Automate"
        description="Learn more about workativ automate's pricing plans. Free workflow automation software."
        keywords={[
          "IT Helpdesk Chatbot",
          "Automate Chatbot",
          "Service Desk Chatbot",
        ]}
        ogTitle="Free Workflow Automation Pricing | Workativ Automate"
        ogDescription="Learn more about workativ automate's pricing plans. Free workflow automation software."
        ogImage={OgImage}
      />
      <Container>
        <div className="automate_pricing automate_product resolution_automate_first_automate_pricing new_automate_background new_mobile_home_design">
          <Layout
            backgroundColor={"bg_feature"}
            logoFor="AUTOMATE"
            product="AUTOMATE"
            fromPage="automate-pricing"
          >
            <div>
              {/*Pricing Start*/}
              <section className="top_section pricing_page new-pricing-page-automate">
                <div className="container-fluid">
                  <div className="row">
                    <div className="container">
                      <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                        <div className="main-slider-left">
                          <h1 className="dek_pricing_page mb-0  font-section-sub-header line-height-15">
                            Start on our free plan, and scale as you grow.{" "}
                            <br></br>Start now. It’s FREE.
                          </h1>
                          <h1 className="mob_pricing_page font-section-sub-header  mb-0">
                            Start on our free plan, and scale as you grow. Start
                            now. It’s FREE.
                          </h1>

                          <div className="form_below_content signup_cta new-font-size-pricing-signup">
                            <span className="font-section-normal-text-medium-pricing">
                              Easy setup.
                            </span>
                            <span className="font-section-normal-text-medium-pricing">
                              No credit card.
                            </span>
                            <span className="font-section-normal-text-medium-pricing">
                              Onboarding included.
                            </span>
                          </div>
                          <div className="plan-new-pricing plan-new-pricing-automate text-align-center">
                            <h6 className="font-section-normal-text-medium">
                              Save more than 15% on Workativ Automate plans by
                              paying annually.
                              <span className="font-section-small-text ml-2 pricing_discounts_assitants">
                                Discounts reflected below.
                              </span>
                            </h6>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <section className="pricing-columns pricing-section">
                  <div className="container">
                    <PricingWrapperV2 product="automate" />
                  </div>
                </section>
              </section>

              <section className="enterprise_wrapper">
                <div className="container">
                  <div className="enterprise_wrapper_content">
                    <div className="enterprise_wrapper_left new-automate-pricing-enterprise-wrapper-left">
                      <div>
                        <span className="pricing-border-enterprise">
                          <PriceEnterpriseIcon />
                        </span>
                      </div>

                      <h6 className="font-section-sub-header-small ml-4">
                        Enterprise
                      </h6>
                    </div>
                    <div className="enterprise_wrapper_center new-automate-enterprise-wrapper-center">
                      <h4 className="font-section-normal-text-medium">
                        Looking for More
                      </h4>
                      {isMobile ? (
                        <p className="enterprise_message font-section-small-text">
                          Enterprise plans are customized. <br></br>
                          please reach out to us at<br></br>
                          <span>
                            <a href="mailto:sales@workativ.com">
                              sales@workativ.com
                            </a>
                          </span>
                          <br></br>
                          and we’d be happy to discuss.
                        </p>
                      ) : (
                        <p className="color-new-enterprise font-section-small-text">
                          Enterprise plans are customised. Please reach out to
                          us at{" "}
                          <span>
                            <a href="mailto:sales@workativ.com">
                              sales@workativ.com
                            </a>
                          </span>{" "}
                          and we'd be happy to discuss.
                        </p>
                      )}
                    </div>
                    <div className="enterprise_wrapper_right new-automate-enterprise_wrapper_right">
                      <button>
                        <a href="">Contact sales</a>
                      </button>
                    </div>
                  </div>
                </div>
              </section>
              {/* Full comparision */}
              <section className="full_comaparision">
                <div className="container">
                  <h1 className="font-section-sub-header-small">
                    All prices are exclusive of applicable taxes{" "}
                  </h1>
                  <p
                    className={`font-section-normal-text ${
                      isMobile ? null : null
                    }`}
                  >
                    24 x 7 email support included with all plans
                  </p>
                  <div
                    className={
                      open
                        ? "show_button_full_comaparison open opened_button_comparison_automation"
                        : "show_button_full_comaparison "
                    }
                  >
                    {isMobile ? null : (
                      <button
                        className="btn btn-primary"
                        onClick={() => {
                          setOpen(!open);
                          setTitle(!title);
                        }}
                      >
                        {" "}
                        {title
                          ? "Show full comparison"
                          : "Hide full comparison"}
                      </button>
                    )}
                  </div>
                  <div className="table_mob_comapre">
                    <div className="table_full_comapare">
                      <table className="table table-bordered table-responsive-sm">
                        <thead>
                          <tr className="tr_bg">
                            <th
                              scope="col"
                              className="font-section-normal-text-medium"
                            >
                              Features
                            </th>
                            <th
                              scope="col"
                              className="font-section-normal-text-medium"
                            >
                              Description
                            </th>
                            <th
                              scope="col"
                              className="font-section-normal-text-medium"
                            >
                              Free
                            </th>

                            <th
                              scope="col"
                              className="font-section-normal-text-medium"
                            >
                              Pro
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr className="tr_bg">
                            <th
                              scope="col"
                              className="font-name-smaller-text-medium"
                            >
                              Workflow Builder{" "}
                            </th>
                            <th scope="col" />
                            <th scope="col" />
                            <th scope="col" />
                          </tr>
                          <tr>
                            <td className="font-name-smaller-text">Canvas</td>
                            <td className="font-name-smaller-text">
                              No-code workflow builder to create app based
                              workflow automations.
                            </td>
                            <td>
                              <YesIcon />
                            </td>

                            <td>
                              <YesIcon />
                            </td>
                          </tr>
                          <tr>
                            <td className="font-name-smaller-text">Testing</td>
                            <td className="font-name-smaller-text">
                              Test your workflows.
                            </td>
                            <td>
                              <YesIcon />
                            </td>

                            <td>
                              <YesIcon />
                            </td>
                          </tr>
                          <tr>
                            <td className="font-name-smaller-text">
                              Multi-Step Workflows
                            </td>
                            <td className="font-name-smaller-text">
                              Ability to create multiple step workflows.
                            </td>
                            <td>
                              <NoIcon />
                            </td>

                            <td>
                              <YesIcon />
                            </td>
                          </tr>
                          <tr>
                            <td className="font-name-smaller-text">
                              Webhook Workflows
                            </td>
                            <td className="font-name-smaller-text">
                              Ability to call workflow via webhooks from 3rd
                              party apps or chatbot platforms.
                            </td>
                            <td>
                              <NoIcon />
                            </td>

                            <td>
                              <YesIcon />
                            </td>
                          </tr>
                          <tr>
                            <td className="font-name-smaller-text">
                              Polling Triggers
                            </td>
                            <td className="font-name-smaller-text">
                              Ability to poll 3rd party applications and execute
                              workflows based on the trigger output.
                            </td>
                            <td className="font-name-smaller-text">
                              Every 15 Min
                            </td>

                            <td className="font-name-smaller-text">
                              Every 2 Min
                            </td>
                          </tr>
                          <tr>
                            <td className="font-name-smaller-text">
                              Event Triggers
                            </td>
                            <td className="font-name-smaller-text">
                              Ability to trigger workflows when an event is
                              triggered from 3rd party applications.
                            </td>
                            <td>
                              <YesIcon />
                            </td>

                            <td>
                              <YesIcon />
                            </td>
                          </tr>
                          <tr>
                            <td className="font-name-smaller-text">
                              One-click App Integrations
                            </td>
                            <td className="font-name-smaller-text">
                              Pre-built app integrations or connectors.
                            </td>
                            <td>
                              <YesIcon />
                            </td>

                            <td>
                              <YesIcon />
                            </td>
                          </tr>
                          <tr className="tr_bg">
                            <th
                              scope="col"
                              className="font-name-smaller-text-medium"
                            >
                              Approval Management
                            </th>
                            <th scope="col" />
                            <th scope="col" />

                            <th scope="col" />
                          </tr>
                          <tr>
                            <td className="font-name-smaller-text">
                              Approval App Workflow
                            </td>
                            <td className="font-name-smaller-text">
                              Add approvals within workflows.
                            </td>
                            <td>
                              <NoIcon />
                            </td>

                            <td>
                              <YesIcon />
                            </td>
                          </tr>
                          <tr className="tr_bg">
                            <th
                              scope="col"
                              className="font-name-smaller-text-medium"
                            >
                              Marketplace{" "}
                            </th>
                            <th scope="col" />
                            <th scope="col" />

                            <th scope="col" />
                          </tr>
                          <tr>
                            <td className="font-name-smaller-text">
                              Pre-built App Workflows
                            </td>
                            <td className="font-name-smaller-text">
                              Download pre-built workflow automation recipes for
                              apps from marketplace.
                            </td>
                            <td>
                              <YesIcon />
                            </td>

                            <td>
                              <YesIcon />
                            </td>
                          </tr>
                          <tr className="tr_bg">
                            <th
                              scope="col"
                              className="font-name-smaller-text-medium"
                            >
                              On-prem Connect
                            </th>
                            <th scope="col" />
                            <th scope="col" />

                            <th scope="col" />
                          </tr>
                          <tr>
                            <td className="font-name-smaller-text">
                              On-prem Connector
                            </td>
                            <td className="font-name-smaller-text">
                              Connect your cloud apps with your on-prem apps
                              securely.
                            </td>
                            <td>
                              <NoIcon />
                            </td>

                            <td>
                              <YesIcon />
                            </td>
                          </tr>

                          <tr className="tr_bg">
                            <th
                              scope="col"
                              className="font-name-smaller-text-medium"
                            >
                              Analytics
                            </th>
                            <th scope="col" />
                            <th scope="col" />

                            <th scope="col" />
                          </tr>

                          <tr>
                            <td className="font-name-smaller-text">
                              Workflow Analytics
                            </td>
                            <td className="font-name-smaller-text">
                              Analyze workflow execution performance.
                            </td>
                            <td>
                              <YesIcon />
                            </td>

                            <td>
                              <YesIcon />
                            </td>
                          </tr>
                          <tr>
                            <td className="font-name-smaller-text">
                              Data availability
                            </td>
                            <td className="font-name-smaller-text">
                              Data available for analysis.
                            </td>
                            <td className="font-name-smaller-text">7 days</td>

                            <td className="font-name-smaller-text">30 days</td>
                          </tr>

                          <tr className="tr_bg">
                            <th
                              scope="col"
                              className="font-name-smaller-text-medium"
                            >
                              Logs
                            </th>
                            <th scope="col" />
                            <th scope="col" />

                            <th scope="col" />
                          </tr>

                          <tr>
                            <td className="font-name-smaller-text">
                              Workflow Logs
                            </td>
                            <td className="font-name-smaller-text">
                              Workflow execution logs to verify success or
                              failed executions.
                            </td>
                            <td>
                              <YesIcon />
                            </td>

                            <td>
                              <YesIcon />
                            </td>
                          </tr>
                          <tr>
                            <td className="font-name-smaller-text">
                              Data availability
                            </td>
                            <td className="font-name-smaller-text">
                              Logs availability period.
                            </td>
                            <td className="font-name-smaller-text">7 days</td>

                            <td className="font-name-smaller-text">30 days</td>
                          </tr>

                          <tr className="tr_bg">
                            <th
                              scope="col"
                              className="font-name-smaller-text-medium"
                            >
                              App Notifications
                            </th>
                            <th scope="col" />
                            <th scope="col" />

                            <th scope="col" />
                          </tr>
                          <tr>
                            <td className="font-name-smaller-text">
                              Admin Notifications
                            </td>
                            <td className="font-name-smaller-text">
                              Product notifications via email and in-app.
                            </td>
                            <td>
                              <YesIcon />
                            </td>

                            <td>
                              <YesIcon />
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <div className="bud_space"></div>
                  </div>

                  {isMobile ? (
                    <div className="show_button_full_comaparison">
                      <button
                        className="btn btn-primary font-section-normal-text-resources-medium"
                        onClick={() => {
                          setOpen(!open);
                          setTitle(!title);
                        }}
                      >
                        {" "}
                        {title
                          ? "Show full comparison"
                          : "Hide full comparison"}
                      </button>
                    </div>
                  ) : null}
                </div>
              </section>
              {/*Pricing End*/}
            </div>
            <section className="top_section top_section_bottom pricing_page automate-new-professional-section-pricing">
              <div className="container-fluid">
                <div className="row">
                  <div className="container">
                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                      <div className="main-slider-left">
                        <h1 className="font-section-sub-header">
                          Professional Services
                        </h1>
                        <h6 className="font-text-medium-goto-solution">
                          Need help on building your first workflow? or create
                          new integrations or workflows for your app?
                        </h6>
                        <p className="font-section-normal-text-testimonials line-height-18">
                          Our professional services can help you design, build,
                          configure and optimize your workflow automation
                          project using our platform. We can also build new
                          recipes or integrations for your own apps or apps that
                          are not in our marketplace. Reach out to us to discuss
                          your requirement.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <div
              className="float-left customer-banner-auto-mobile-pricing"
              style="width: 100%;"
            >
              <div className="container mb-5 ">
                <div className="d-flex customer-home-container">
                  <h5 className="text-center margin-bottom-20 font-section-sub-header">
                    Join leading innovators
                  </h5>{" "}
                  {isMobile ? (
                    <img
                      loading="lazy"
                      className=""
                      src={bannerImageMobile}
                      alt="join leading innovators"
                    />
                  ) : (
                    <img
                      loading="lazy"
                      className=""
                      src={bannerImage}
                      alt="join leading innovators"
                    />
                  )}
                </div>
              </div>
            </div>
            {isMobile ? (
              <AutomateTestimonialsMobile
                additionalClassName={"bg-testimonials-automate"}
                isHeader={false}
              />
            ) : (
              <AutomateTestimonials
                additionalClassName={"bg-testimonials-automate"}
                isHeader={false}
              />
            )}
            <div className="automate_homepage">
              <Faq />
            </div>
            <div className="automate_homepage">
              <RequestForm
                product="automate"
                content="Start on our free plan, and scale up as you grow."
                AutomateCTA
                automateContent
                autoamteButton
                automateName
                isFooterForm={true}
                fromPage="automate-pricing"
              />
            </div>
            {plan == "" ? null : (
              <RequestFormModal
                setPlan={setPlan}
                plan={plan}
                showThanksPage={showThanksPage}
                setShowThanksPage={setShowThanksPage}
                product
                fromPage="automate-pricing"
              />
            )}
          </Layout>
        </div>
      </Container>
    </>
  );
}

function Faq() {
  return (
    <React.Fragment>
      <div className="mob_accordian mob_accordian_faq new-faq-pricing">
        <section className="accordian_landing trial_accordian_landing">
          <div className="container">
            <h2 className="font-section-sub-header">
              Pricing Frequently Asked Questions
            </h2>
            <div className="col-12 pl-0 faq_landing">
              <Accordion
                id="accordion"
                className="accordion pl-0"
                preExpanded={["a"]}
              >
                <div className="accordion-wrapper pricing_faq">
                  {faqData.map((data) => (
                    <AccordionItem uuid={data.uuid}>
                      <AccordionItemHeading>
                        <AccordionItemButton>{data.header}</AccordionItemButton>
                      </AccordionItemHeading>
                      <AccordionItemPanel>
                        <p
                          dangerouslySetInnerHTML={{ __html: data.paragraph }}
                          className="font-section-normal-text-testimonials line-height-18"
                        />
                      </AccordionItemPanel>
                    </AccordionItem>
                  ))}
                </div>
              </Accordion>
            </div>
          </div>
        </section>
      </div>
    </React.Fragment>
  );
}

export const faqData = [
  {
    header: "What is an active workflow?",
    paragraph:
      "A workflow is a sequence of tasks that process a set of actions or data. Each subscription plan comes with a quota or limit of workflows that can be active at a given time.",
    active: "active",
    uuid: "a",
  },
  {
    header: "What is a multi-step workflow?",
    paragraph:
      "Multi-step workflows are workflows where you can have more than one action in a single workflow. Multi-step workflows are used to create workflows with multiple applications.",
  },
  {
    header: "What is workflow execution?",
    paragraph:
      "Workflow execution is a combination of triggers and actions. A workflow is executed using a trigger or webhook followed by an Action. Depending on your subscription plan, you can have multiple actions in a single workflow.",
  },
  {
    header: "How do we calculate workflow executions?",
    paragraph:
      "A workflow execution is calculated as 1 execution, irrespective of number of actions within the workflow. For example, when a new user is added in BambooHR app, you want to add the user in Active Directory and create a ticket in ServiceNow. In this workflow you have used 2 actions, but we only calculate as 1 workflow execution. If you execute the workflow 10 times, 10 workflow executions will be consumed from your available execution limit or quota depending on your subscription plan.",
  },
  {
    header: "What are additional consumptions?",
    paragraph:
      "Each subscription plan comes with a workflow execution quota or limit. Once you exceeds this quota or limit, you will be charged $0.1 for each additional workflow execution.",
  },
  {
    header: "What is on-prem connector?",
    paragraph:
      "Workativ’s On-prem Connector provides a secure connection between our platform and your on-prem apps behind your corporate firewall. For increased level of security, workativ relays HTTPS calls to workativ agent behind your corporate firewall to execute workflows with your on-prem apps.",
    // paragraph1:
    //   "Though we do not charge by user count, the Basic plan is ideal for an employee strength in the range of 250-300, Standard plan is ideal for users in the rage of 600 or less, and Pro plan in the range of 1500 or less. And secondly, higher ticket volume may require more active automations.",
    // paragraph2:
    //   "We can help you decide based on your needs, so just shoot us an email at sales@workativ.com."
  },
  {
    header: "How safe is my data?",
    paragraph: `We take security seriously. Our servers are hosted in world-class data centers in AWS that are protected by biometric locks and 24-hour surveillance. We ensure that our application and servers are always up to date with the latest security patches. All plans include SSL for encryption to keep your data safe. We are ISO Certified and you can find more on our security measures <span><a href="/security">here</a></span>.`,
  },
  {
    header: "Payment options?",
    paragraph:
      "We accept account transfer, wire transfer, and all major credit card payments. For more details, please contact <a href='mailto:sales@workativ.com'>sales@workativ.com</a> ",
  },
  {
    header: "Invoice and payments?",
    paragraph:
      "Invoice is raised as soon as you sign-up and start using the app, post-trial period. Payments are due within 7 days of invoice. Additional usage of workflow executions are included in the next invoice.",
  },
  {
    header: "Can I change my plan anytime?",
    paragraph: "For now, you can switch to higher plans only.",
  },
  {
    header: "Is there any premium support? ",
    paragraph:
      "Premium support is available as a custom plan or in an enterprise plan. Please reach out us to discuss your requirements.",
  },
  {
    header: "More questions on plans and pricing?",
    paragraph:
      "If you have any additional questions or clarification on pricing or subscription, please reach out to us at <a href='mailto:sales@workativ.com'>sales@workativ.com</a> and we’ll be happy to help.",
  },
];
